import React from "react"
import { Container, Col, Row } from "reactstrap"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { FaCheck } from "react-icons/fa"

let StyledFeature = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition-duration: 0.25s;
  &:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  }
`

let Feature = ({ title, description, img }) => (
  <Col md={4} className="mb-3">
    <StyledFeature>
      <Img fluid={img} />
      <div className="p-3">
        <h5>{title}</h5>
        {description.map((item) => (
          <div className="d-flex align-items-center mb-4">
            <FaCheck
              color="green"
              className="mr-3"
              style={{ minWidth: "30px" }}
            />
            {item}
          </div>
        ))}
      </div>
    </StyledFeature>
  </Col>
)

let HomeFeatures = () => {
  const data = useStaticQuery(graphql`
    fragment defaultImage on File {
      childImageSharp {
        fluid(maxWidth: 400, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query featuresQuery {
      slide1: file(relativePath: { eq: "corps-etat-architecturaux.jpg" }) {
        ...defaultImage
      }
      slide2: file(relativePath: { eq: "corps-etat-techniques.jpg" }) {
        ...defaultImage
      }
      slide3: file(relativePath: { eq: "etancheite.jpg" }) {
        ...defaultImage
      }
      slide4: file(relativePath: { eq: "exterieur.jpg" }) {
        ...defaultImage
      }
      slide5: file(relativePath: { eq: "terrains-prives.jpg" }) {
        ...defaultImage
      }
      slide6: file(relativePath: { eq: "gros-oeuvre.jpg" }) {
        ...defaultImage
      }
    }
  `)
  return (
    <Container className="py-5">
      <div className="mb-4">
        <h2 className="text-primary">TRAVAUX</h2>
      </div>
      <Row>
        <Feature
          title="GROS ŒUVRE"
          description={[
            "Terrassement",
            "Structures béton ou métallique",
            "Maçonnerie et enduits",
            "Assainissement, Étanchéité",
            "Aménagements extérieurs/VRD",
          ]}
          img={data.slide6.childImageSharp.fluid}
        />
        <Feature
          title="CORPS D’ÉTATS TECHNIQUES"
          description={[
            "CFO",
            "postes de transformateurs",
            "distribution",
            "CFA",
            "Climatisation",
            "Plomberie",
            "Traitement d’eau piscine",
          ]}
          img={data.slide2.childImageSharp.fluid}
        />
        <Feature
          title="CORPS D’ÉTATS ARCHITECTURAUX"
          description={[
            "Faux plafonds Staff lisse, BA13, acoustique",
            "Revêtements en dur, revêtement souple, revêtement de façade en pierre taillée",
            "Menuiserie métallique, tôle découpée laser",
            "Menuiserie Bois",
            "Menuiserie aluminium : Mur rideau, baies vitrées, menuiserie pare flamme",
            "Peintures courantes, peinture décoratives",
          ]}
          img={data.slide1.childImageSharp.fluid}
        />
        <Feature
          title="ETANCHÉITÉ"
          description={[
            "Pour terrasses inaccessibles auto protégées",
            "Pour terrasses végétalisées",
          ]}
          img={data.slide3.childImageSharp.fluid}
        />
        <Feature
          title="AMÉNAGEMENTS EXTÉRIEURS"
          description={[
            "Réseaux enterrés (Assainissement, AEP, M.T)",
            "Béton balayé, béton désactivé, béton imprimé, enrobés, pavés autobloquants, parking végétalisés perméables",
          ]}
          img={data.slide4.childImageSharp.fluid}
        />
        <Feature
          title="ESPACES SPORTIFS"
          description={[
            "Résine acrylique, résine polyuréthane, Sol amortissant",
          ]}
          img={data.slide5.childImageSharp.fluid}
        />
      </Row>
    </Container>
  )
}

export default HomeFeatures
