/* eslint-disable import/no-anonymous-default-export */
import React from "react"
import { Col, Row, Container } from "reactstrap"
import { FaStar } from "react-icons/fa"
import styled from "styled-components"

let Background = styled.div`
  background: linear-gradient(to bottom, #f9fbfd 0, #fff 100%);
`

let Testimonial = ({ stars = 5, content, client, entreprise }) => (
  <Col md={4}>
    {[...Array(stars)].map((star) => (
      <FaStar className="mr-1" color="#ec9a3c" />
    ))}
    <p className="mt-3">{content}</p>
    <h6 className="font-weight-bold mb-0">{client}</h6>
    <small className="text-secondary">{entreprise}</small>
  </Col>
)

export default () => (
  <Background>
    <Container className="py-5">
      <h2 className="text-center">Témoignages de clients</h2>
      <Row className="d-flex justify-content-center">
        <Col md="7">
          <p className="text-center text-secondary"></p>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center mt-4">
        <Testimonial
          content="Réalisation très soignée malgré une conception délicate. Chantier bien tenu et choix des sous-traitants pertinent 
								et adapté aux ouvrages à réaliser en particulier l'enveloppe du bâtiment."
          client="Abdlhafid Sellak"
          entreprise="Société Sobeton"
        />
        <Testimonial
          content="Vous pouvez féliciter votre personnel pour ses compétences 
								et l'attitude positive qu'il apporte sur le chantier. On sent une vraie satisfaction de bien faire. Les synergies mises en place notamment lors de recherches de solutions techniques ont été particulièrement appréciées."
          client="Alexander Samokhin"
          entreprise="Architecte"
        />
      </Row>
    </Container>
  </Background>
)
