import React from "react"

import { Container, Row, Col } from "reactstrap"
import ScrollToTop from "react-scroll-to-top"

import Link from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaHandshake, FaHome, FaWrench } from "react-icons/fa"
import { AiFillLike } from "react-icons/ai"
import Form from "../components/form"
import Slider from "../components/slider"
import Box from "../components/box"
import Hr from "../components/hr"
import Benefits from "../components/benefits"
import styled from "styled-components"
import HomeFeatures from "../components/homeFeatures"
import Testimonials from "../components/testimonials"
import Clients from "../components/clients"

let StyledBackground = styled.div`
  background: linear-gradient(to bottom, #f9fbfd 0, #fff 100%);
`

let Service = ({ title, Icon = FaHome }) => (
  <Col xs={12} md={4} className="mb-3">
    <Link to="/">
      <Box>
        <Icon size={30} />
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
)

let Index = () => (
  <Layout>
    <SEO title="Home" />
    <ScrollToTop smooth color="#007bff" />
    <Slider />
    <Container className="pt-4">
      <div className="text-center">
        <h4>QUI SOMMES-NOUS?</h4>
        <p className="text-muted">
          Ait Lhadj Ben Amouch De Travaux est une entreprise générale de BTP qui
          vous assure la livraison de votre ouvrage, toutes fonctions et
          techniques assemblées, conforme et en état d’achèvement, au meilleur
          rapport qualité/prix. Nous vous accompagnons dès l’expression de vos
          besoins jusqu’à l’accomplissement des projets de vos travaux.
        </p>
      </div>
    </Container>
    <Container className="py-5">
      <h2 className="text-center mb-4">Valeurs</h2>
      <Row>
        <Service title="Qualité" Icon={AiFillLike} />
        <Service title="Confiance et Bienveillance" Icon={FaHandshake} />
        <Service title="Professionalisme" Icon={FaWrench} />
      </Row>
    </Container>
    <StyledBackground>
      <Benefits />
    </StyledBackground>
    <HomeFeatures />
    <Testimonials />
    <Clients />
    <div className="py-5">
      <Container>
        <Row className="d-flex justify-content-center">
          <Col md={8}>
            <Box style={{ textAlign: "left" }}>
              <h3 className="text-center">NOUS CONTACTER</h3>
              <Hr />
              <Form />
            </Box>
          </Col>
        </Row>
      </Container>
    </div>
  </Layout>
)

export default Index
