import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

const Background = styled.div`
  padding: 40px 0;
  text-align: center;
  overflow: hidden;
`
const StyledImg = styled(Img)``
const ImgWrapper = styled.div``

let Clients = ({ title }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
    ],
  }
  const data = useStaticQuery(graphql`
    fragment clientLogo on File {
      childImageSharp {
        fixed(height: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    query clientsLogoQuery {
      slide1: file(relativePath: { eq: "clients/ancfcc.png" }) {
        ...clientLogo
      }
      slide2: file(relativePath: { eq: "clients/barid-al-maghrib.png" }) {
        ...clientLogo
      }
      slide3: file(
        relativePath: { eq: "clients/casablanca-iskan-equipement.png" }
      ) {
        ...clientLogo
      }
      slide4: file(relativePath: { eq: "clients/cnss.png" }) {
        ...clientLogo
      }
      slide5: file(relativePath: { eq: "clients/crmef.png" }) {
        ...clientLogo
      }
      slide6: file(relativePath: { eq: "clients/entraide-nationale.png" }) {
        ...clientLogo
      }
      slide7: file(relativePath: { eq: "clients/prefecture-police.png" }) {
        ...clientLogo
      }
      slide8: file(relativePath: { eq: "clients/ministere-sante.png" }) {
        ...clientLogo
      }
      slide9: file(
        relativePath: { eq: "clients/centre-hospitalier-mohamed-6.jpg" }
      ) {
        ...clientLogo
      }
    }
  `)
  return (
    <Background>
      <h2 className="text-center mb-5">Nos Clients</h2>
      <Slider {...settings}>
        <ImgWrapper>
          <StyledImg fixed={data.slide1.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide2.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide8.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide3.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide4.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide5.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide6.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide7.childImageSharp.fixed} />
        </ImgWrapper>
        <ImgWrapper>
          <StyledImg fixed={data.slide9.childImageSharp.fixed} />
        </ImgWrapper>
      </Slider>
    </Background>
  )
}

export default Clients
