import React from "react"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

import "../../node_modules/slick-carousel/slick/slick.css"
import "../../node_modules/slick-carousel/slick/slick-theme.css"

const SliderContainer = styled.div`
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(9, 9, 121, 1) 4%,
    rgba(0, 212, 255, 1) 100%
  );
  color: white;
  padding: 10rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0% 100%);
`
const ImageContaier = styled(Img)`
  position: absolute;
  bottom: 0px;
  width: 100%;
`
let SlickSlider = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  }

  const data = useStaticQuery(graphql`
    fragment sliderImg on File {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    query SliderImgQuery {
      sliderImg: file(relativePath: { eq: "slider.jpg" }) {
        ...sliderImg
      }
    }
  `)

  return (
    <SliderContainer style={{ position: "relative", overflow: "hidden" }}>
      <ImageContaier
        fluid={data.sliderImg.childImageSharp.fluid}
        style={{ position: "absolute", height: "100%" }}
      />
      <Slider {...settings}>
        <div>
          <div className="text-center">
            <h1 style={{ textTransform: "uppercase" }}>GROS ŒUVRE</h1>
            <p>
              Fondations, Superstructure, Précontrainte, Maçonnerie, isolation
              thermique.
            </p>
          </div>
        </div>
        <div>
          <div className="text-center">
            <h1 style={{ textTransform: "uppercase" }}>
              CORPS D’ETATS ARCHITECTURAUX
            </h1>
            <p>
              Faux plafonds Staff lisse, BA13, acoustique, revêtements en dur,
              revêtement souple...
            </p>
          </div>
        </div>
        <div>
          <div className="text-center">
            <h1 style={{ textTransform: "uppercase" }}>
              aménagements extérieurs
            </h1>
            <p>
              Réseaux enterrés (Assainissement, AEP, M.T) Béton balayé, béton
              désactivé, béton imprimé...
            </p>
          </div>
        </div>
      </Slider>
    </SliderContainer>
  )
}

export default SlickSlider
